import * as React from 'react';
import classNames from 'classnames';
import { VideoBoxCommonProps } from '../VideoBox.types';
import FillLayers from '../../FillLayers/viewer/FillLayers';
import { isCSSMaskSupported } from '../../../core/commons/utils';
import { useIsomorphicLayoutEffect } from '../../../providers/useIsomorphicLayoutEffect';
import styles from './style/VideoBox.scss';
import { getVideoEvents, getVisibilityHandlers } from './eventHandlers';
import {
  parseSVGContentFromMaskImage,
  getSvgImageWithMask,
} from './maskedVideoBoxFallback';
import * as transparentUtils from './transparentVideoUtils';

const noMaskSupport = !isCSSMaskSupported();
const isIE = transparentUtils.isIEAgent();

const ARIA_LABEL_NAMESPACE = 'ariaLabels';
const VIDEOBOX_PLAY_ARIA_LABEL_KEY = 'VideoBox_AriaLabel_Play_Video';
const VIDEOBOX_PLAY_ARIA_LABEL_DEFAULT = 'Play video';

const VideoBoxCommon: React.FC<VideoBoxCommonProps> = ({
  id,
  translate,
  mediaControls,
  containerRootClassName,
  compRef,
  fillLayers,
  reducedMotion = false,
  isMobileView,
  audioEnabled,
  hasAudio,
  hasClick,
  hasRollIn,
  hasAudioRollIn,
  canClickPause,
  canRollPause,
  muted,
  autoplay,
  canReplay,
  hasMask,
  animatePoster,
  filterEffect,
  isTransparent = false,
  alt,
  updateState,
  onPlay,
  onPause,
  onEnded,
  onProgress,
  getPlaceholder,
}) => {
  const playerRef = React.useRef<HTMLDivElement>(null);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const playButtonRef = React.useRef<HTMLDivElement>(null);
  const audioRef = React.useRef<HTMLDivElement>(
    null,
  ) as React.MutableRefObject<HTMLDivElement | null>;
  const kampos = React.useRef<transparentUtils.Kampos>(null);
  const contextRef = React.createRef();

  const accessibleAutoplay = autoplay && !reducedMotion;

  const initCorvidState = React.useCallback(
    (video: HTMLVideoElement | null) => {
      if (!updateState) {
        return;
      }

      // get comp state after src set via code
      const isMuted = playerRef.current?.dataset.audio !== 'on';
      const volume = +(playerRef.current?.dataset.volume || 1);

      const videoEl = video || {
        paused: true,
        currentTime: 0,
        duration: 0,
        volume,
        muted: isMuted,
      };

      // init Corvid state
      updateState({
        isPlaying: !videoEl.paused,
        currentTime: videoEl.currentTime || 0,
        duration: videoEl.duration || 0,
        volume: volume * 100,
        isMuted,
        shouldPlay: autoplay,
      });

      if (video) {
        // sync volume DOM property with comp state, basically after src was set via code
        video.volume = volume;
      }
    },
    [autoplay, updateState],
  );

  const [canPlayTV, setCanPlayTV] = React.useState(
    isTransparent ? !isIE : true,
  );
  const [isMasked, setIsMasked] = React.useState(hasMask);
  // set the flag on state for render-related stuff
  const [isPosterOnly, setIsPosterOnly] = React.useState(
    isTransparent ? !canPlayTV : false,
  );
  // calculate local value for side-effects
  const currentIsPosterOnly = !!(hasMask && noMaskSupport);
  const isInteractive = React.useMemo(() => canPlayTV && !isPosterOnly, [
    canPlayTV,
    isPosterOnly,
  ]);

  const tvController = React.useMemo(() => {
    return isTransparent && canPlayTV
      ? transparentUtils.tvControllerFactory({
          filterEffect,
          playerRef,
          videoRef,
          canvasRef,
          contextRef,
          kampos,
          setCanPlayTV,
        })
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterEffect, contextRef, isTransparent, canPlayTV, fillLayers]);

  const extraMediaHandlers = React.useMemo(() => {
    const handlers: Record<string, Array<(e: Event) => void>> = {
      play: [],
      pause: [],
      ended: [],
      timeupdate: [],
    };

    // if we have platform we add state update handlers
    if (updateState) {
      const updateIsPlaying = (e: Event) =>
        updateState({
          // @ts-ignore
          isPlaying: !e.target.paused,
          // @ts-ignore
          duration: e.target.duration,
        });

      const updateCurrentTime = (e: Event) =>
        // @ts-ignore
        updateState({ currentTime: e.target.currentTime });

      handlers.loadeddata = [
        (e: Event) => {
          initCorvidState(e.target as HTMLVideoElement);
        },
      ];

      handlers.play.push(updateIsPlaying);
      handlers.pause.push(updateIsPlaying);
      handlers.ended.push(updateIsPlaying);
      handlers.timeupdate.push(updateCurrentTime);
    }

    // if it's a Transparent Video we handle poster removal
    if (isTransparent && tvController) {
      handlers.play.push(tvController.removePoster);
    }

    // Corvid event handlers
    if (onPlay) {
      handlers.play.push(() => onPlay({ type: 'onPlay' }));
    }
    if (onPause) {
      handlers.pause.push(() => onPause({ type: 'onPause' }));
    }
    if (onEnded) {
      handlers.ended.push(() => onEnded({ type: 'onEnded' }));
    }
    if (onProgress) {
      handlers.timeupdate.push(() => onProgress({ type: 'onProgress' }));
    }

    return handlers;
  }, [
    onPlay,
    onPause,
    onEnded,
    onProgress,
    isTransparent,
    initCorvidState,
    tvController,
    updateState,
  ]);

  const shouldAudioPlay = audioEnabled && hasAudio;

  const {
    videoAPI,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onMouseMove,
    onTouchEnd,
    onKeyDown,
    mediaHandler,
    setAudioContext,
    MEDIA_EVENTS,
  } = React.useMemo(
    () =>
      getVideoEvents({
        playerRef,
        videoRef,
        playButtonRef,
        audioRef,
        isMobileView,
        autoplay: accessibleAutoplay,
        hasAudio: shouldAudioPlay,
        hasClick,
        hasRollIn,
        hasAudioRollIn,
        canClickPause,
        canRollPause,
        canReplay,
        extraMediaHandlers,
        updateState,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      playerRef,
      videoRef,
      videoRef.current,
      extraMediaHandlers,
      updateState,
      shouldAudioPlay,
    ],
  );

  /*
   * Set DOM references for controls
   *
   * Visibility event handlers.
   *
   * Will also trigger TV controller's init and play, and destroy on cleanup.
   */
  useIsomorphicLayoutEffect(() => {
    if (isInteractive && playerRef.current) {
      // init Audio
      if (!audioRef.current) {
        audioRef.current = playerRef.current.querySelector('[data-audio-mute]');
      }
    }

    // update state with local value
    setIsPosterOnly(currentIsPosterOnly);

    // when getting partial data reset DOM state
    if (!fillLayers?.video?.videoInfo?.isVideoDataExists && playerRef.current) {
      // Transparent video poster reset
      delete playerRef.current.dataset.showCanvas;
    }

    if (currentIsPosterOnly || (isTransparent && !canPlayTV)) {
      return;
    }

    if (isTransparent || shouldAudioPlay || hasClick) {
      const handlersCleanup = getVisibilityHandlers({
        playerRef,
        videoRef,
        onViewEnter: tvController?.onViewEnter,
        onViewLeave: tvController?.onViewLeave,
      });

      return function cleanup() {
        handlersCleanup?.();
        tvController?.killKampos();
      };
    }
    return;
  }, [
    isTransparent,
    playerRef,
    videoRef,
    videoRef.current,
    canvasRef,
    canPlayTV,
    tvController,
    shouldAudioPlay,
  ]);

  /*
   * Video media event handlers
   *
   * Init Corvid state
   */
  React.useEffect(() => {
    if (isTransparent && !canPlayTV) {
      return;
    }

    const videoEl = videoRef.current;
    /*
     * Merge MEDIA_EVENTS with calculated extra handlers
     */
    const activeMediaEvents = new Set(
      Array.from(MEDIA_EVENTS).concat(
        Object.keys(extraMediaHandlers).filter(eventType => {
          return (
            extraMediaHandlers[eventType] &&
            extraMediaHandlers[eventType].length
          );
        }),
      ),
    );

    activeMediaEvents.forEach(eventType => {
      if (!videoEl || currentIsPosterOnly) {
        return;
      }

      if (
        eventType === 'loadeddata' &&
        videoEl.readyState >= videoEl.HAVE_CURRENT_DATA
      ) {
        mediaHandler.handleEvent({ type: eventType } as Event);
        initCorvidState(videoRef.current);
      } else {
        videoEl.addEventListener(eventType, mediaHandler);
      }
    });

    if (setAudioContext && !currentIsPosterOnly) {
      setAudioContext();
    }

    return () => {
      activeMediaEvents.forEach(eventType => {
        if (!videoEl) {
          return;
        }

        videoEl.removeEventListener(eventType, mediaHandler);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isTransparent,
    canPlayTV,
    videoRef,
    videoRef.current,
    setAudioContext,
    mediaHandler,
    MEDIA_EVENTS,
    currentIsPosterOnly,
    extraMediaHandlers,
    updateState,
    initCorvidState,
  ]);

  /*
   * Create CSS mask fallback if needed (Edge < 18)
   */
  useIsomorphicLayoutEffect(() => {
    const playerEl = playerRef.current;

    // implies: browser only && has mask && css mask not supported
    if (currentIsPosterOnly && fillLayers.video && playerEl) {
      const style = window.getComputedStyle(playerEl);
      // read and parse the SVG content from the mask custom property
      const svgContent = parseSVGContentFromMaskImage(
        style.getPropertyValue('--mask-image'),
      );

      // mask the poster image
      fillLayers.video.posterImageInfo.maskDataElementString = getSvgImageWithMask(
        {
          svgContent,
          compId: id,
          alt: fillLayers.video.posterImageInfo.alt || '',
        },
      );
      // disable video
      fillLayers.video.isVideoEnabled = false;

      // rerender and make the fallback visible
      setIsMasked(false);
    }
  }, [playerRef, id, fillLayers.video, currentIsPosterOnly]);

  React.useImperativeHandle(compRef, () => {
    return videoAPI;
  });

  return (
    <div
      id={id}
      ref={playerRef}
      className={classNames(styles.videobox, containerRootClassName, {
        [styles.masked]: isMasked,
      })}
      onClick={isInteractive ? onClick : undefined}
      onMouseEnter={isInteractive ? onMouseEnter : undefined}
      onMouseLeave={isInteractive ? onMouseLeave : undefined}
      onMouseMove={isInteractive ? onMouseMove : undefined}
      onTouchEnd={isInteractive ? onTouchEnd : undefined}
      onKeyDown={isInteractive ? onKeyDown : undefined}
      data-audio={muted ? 'off' : 'on'}
      data-has-play={isInteractive && (hasClick || hasRollIn) ? '' : undefined}
      data-no-audio={isInteractive && shouldAudioPlay ? undefined : ''}
      data-playing={isInteractive && accessibleAutoplay ? '' : undefined}
      data-stop={isInteractive ? undefined : ''}
      data-animate-poster={isTransparent ? animatePoster : undefined}
      data-has-alpha={isTransparent ? 'true' : undefined}
    >
      <div
        ref={playButtonRef}
        className={classNames(styles.videoboxContainer, {
          [styles.hasCanvas]: isTransparent,
        })}
        tabIndex={0}
        role="button"
        aria-label={`${alt} ${translate!(
          ARIA_LABEL_NAMESPACE,
          VIDEOBOX_PLAY_ARIA_LABEL_KEY,
          VIDEOBOX_PLAY_ARIA_LABEL_DEFAULT,
        )}`}
        aria-pressed={accessibleAutoplay ? 'true' : 'false'}
      >
        <FillLayers
          {...fillLayers}
          getPlaceholder={getPlaceholder}
          reducedMotion={reducedMotion}
          videoRef={videoRef}
          canvasRef={isTransparent ? canvasRef : undefined}
          extraClass={styles.videoboxFill}
        />
      </div>
      {isInteractive ? mediaControls : null}
    </div>
  );
};

export default VideoBoxCommon;
